import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[4];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              {/* battle */}
              <div className="big-description">
                <p>
                  Looking to impress somebody with some elegant visit to one of
                  Austin's most infamous Mexican eateries? Well grab your bowtie
                  and be sure to visit Suerte, by chef Fermín Núñez. A
                  considerable amount of time is dedicated to the core menu,
                  from the now-famous Black Magic Oil to pastry chef Derrick
                  Flynn's interpretation of a Choco Taco.
                </p>
                <p>
                  Our top choice was the "Suadero" tacos that was a jaw-dropping
                  confit brisket, black magic oil, and avocado crudo. Núñez was
                  among Food & Wine's Best Chefs, and has been featured on Food
                  Network, Netflix’s The Taco Chronicles, The Wall Street
                  Journal, New York Times, and Texas Monthly.
                </p>
                <p></p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
